import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
// components
import NotificationCenter from 'components/organisms/common/NotificationCenter';
import NotifyIcon from 'components/atoms/icons/Notify';
import Avatar from 'components/atoms/photos/Avatar';
import Icon from 'cds/icons';
import IconButton from 'cds/buttons/Icon';
import FillButton from 'cds/buttons/Fill';
import OutlineButton from 'cds/buttons/Outline';
// hooks
import { useSelector } from 'hooks/common/useStore';
import useBridgeCallback from 'hooks/bridge/useBridgeCallback';
import useSearchModal from 'hooks/common/useSearchModal';
import useAccordingToScroll from 'hooks/common/useAccordingToScroll';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
import { isExistWindow } from 'lib/utils/helper';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery, zIndex } from 'cds/styles';

/** 메인단 검색포함 헤더 */
const MainMobileHeader = () => {
  const router = useRouter();
  const [userInfo, existChat, existNotification] = useSelector(state => [
    state.user.info,
    state.user.newsCount.chat_unread_count,
    state.user.newsCount.news_unread_count,
  ]);

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [isEnableNotify, setIsEnableNotify] = useState(false);
  const [isVisibleNotify, setIsVisibleNotify] = useState(false);

  const isExistUser = userInfo.isLoaded && userInfo.id;

  const { onOpenSearch } = useSearchModal();

  const [stickyRef, isSticky] = useAccordingToScroll({ start: 0 });

  useBridgeCallback({ onOpenNotify });

  const onSearchSubmit = e => e.preventDefault();

  const onLogin = () => {
    const loginRedirectUrl = isExistWindow() ? window.location.pathname : '';
    router.push(`/user?url=${loginRedirectUrl}`);
  };

  const onToggleNotify = () => {
    if (!isExistUser) {
      onLogin();
      return;
    }
    setIsVisibleNotify(v => !v);
  };

  function onOpenNotify() {
    const notifyEvent = new CustomEvent('onOpenNotify', {
      detail: {
        winWidth: window.outerWidth,
      },
    });

    document.dispatchEvent(notifyEvent);
  }

  const doOpenNotify = ({ detail }) => {
    const { winWidth } = detail;
    if (winWidth < mediaQuery.large) {
      return;
    }

    setIsVisibleNotify(true);
  };

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  /** 알림 아이콘 표시 여부 */
  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      setIsEnableNotify(false);
    } else {
      setIsEnableNotify(true);
    }
  }, []);

  useEffect(() => {
    if (!isEnableNotify) {
      return;
    }

    document.addEventListener('onOpenNotify', doOpenNotify);

    return () => {
      document.removeEventListener('onOpenNotify', doOpenNotify);
    };
  }, [isEnableNotify]);

  return (
    <>
      <Wrapper>
        <Header>
          <Inner>
            <Logo>
              <a href="/">
                <IconWrapper>
                  <Icon name="logo_white" width={110} height={20} />
                  <Icon name="logo_symbol" width={12} height={13} />
                </IconWrapper>
              </a>
            </Logo>
            <Service>
              {!isExistUser && userInfo.isLoaded && (
                <a href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/open`}>
                  <ClassApplyButton size="32px" color={`${palette.grey020}`}>
                    클래스 개설
                  </ClassApplyButton>
                </a>
              )}

              {isExistUser && (
                <a href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat`}>
                  <NotifyIcon
                    name="ic_chat"
                    fill={palette.grey50}
                    width={24}
                    height={24}
                    isActive={!!existChat}
                  />
                </a>
              )}

              {isExistUser && isEnableNotify && (
                <IconButton width={24} height={24} onClick={onToggleNotify}>
                  <NotifyIcon
                    name="ic_alarm_s"
                    fill={palette.grey50}
                    width={24}
                    height={24}
                    isActive={!!existNotification}
                  />
                </IconButton>
              )}

              {isExistUser && (
                <Link href={`/user/${userInfo.id}`} passHref>
                  <AvatarButton as="a">
                    <Avatar width={32} height={32} src={userInfo.photo} alt={userInfo.name} />
                  </AvatarButton>
                </Link>
              )}

              {!isExistUser && userInfo.isLoaded && (
                <LoginButton size="32px" color={palette.grey050} onClick={onLogin}>
                  로그인
                </LoginButton>
              )}
            </Service>
          </Inner>

          {!isIpadApp && (
            <SearchForm
              ref={stickyRef}
              isSticky={isSticky}
              onClick={onOpenSearch}
              onSubmit={onSearchSubmit}
            >
              <label>
                <input placeholder="무엇을 배우고 싶나요?" />
                <Icon name="ic_number_sign_s" stroke={palette.red040} width={16} height={16} />
                <Icon name="ic_search_top_bar" stroke={palette.grey070} width={16} height={16} />
              </label>
            </SearchForm>
          )}
        </Header>
        <div />
      </Wrapper>

      {isExistUser && (
        <NotifyWrapper>
          <NotificationCenter isVisible={isVisibleNotify} onClose={onToggleNotify} />
        </NotifyWrapper>
      )}
    </>
  );
};

const Wrapper = styled.header`
  height: 56px;

  & > div:last-of-type {
    height: inherit;
  }
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: inherit;
  z-index: ${zIndex.header};
  box-sizing: content-box;
`;

const Inner = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  top: 0;
  right: 0;
  left: 0;
  height: inherit;
  padding: 0 16px;
  background-color: ${palette.system.BNB};
  z-index: ${zIndex.header};
`;

const Logo = styled.div`
  flex: 0 auto;
  font-size: 0;

  & > a {
    display: block;
  }
`;

const LoginButton = styled(OutlineButton)`
  padding: 0 12px;
  margin-left: 12px !important;
  ${typography.subtitle4_medium}
  color: ${palette.grey080};
  background-color: transparent;
  color: ${palette.grey75};
  border: 1px solid ${palette.system.grey};
`;

const ClassApplyButton = styled(FillButton)`
  padding: 0 12px;
  ${typography.subtitle4_medium}
  color: ${palette.grey080};
  background-color: ${palette.BTN.BG};
  color: ${palette.grey50};
  & ~ ${LoginButton} {
    margin-left: 6px;
  }
`;

const Service = styled.div`
  display: flex;
  align-items: center;
  flex: 0 auto;

  & > * ~ * {
    margin-left: 12px;
  }
`;

const SearchForm = styled.form`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  padding: 4px 16px 8px;
  background-color: ${palette.system.BNB};
  transition: transform 0.2s ease;
  transform: ${({ isSticky }) => (isSticky ? 'translate3d(0, -100%, 0)' : 'translate3d(0, 0, 0)')};
  z-index: calc(${zIndex.header} - 1);

  & label {
    display: block;
    position: relative;
    height: 44px;
  }

  & label,
  & input {
    width: 100%;
  }

  & input,
  & input::placeholder {
    ${typography.body2_regular}
    color: ${palette.grey0};
  }

  & input {
    height: inherit;
    padding: 0 44px 0 32px;
    border: 1px solid ${palette.system.grey};
    border-radius: 8px;
    caret-color: ${palette.red040};
    appearance: none;
    outline: none;
    background-color: transparent;

    & ~ svg {
      position: absolute;
      top: 15px;
    }

    & ~ svg:first-of-type {
      left: 14px;
    }

    & ~ svg:last-of-type {
      right: 14px;
      stroke: ${palette.grey0};
    }
  }
`;

const AvatarButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  & > div {
    border: 1px solid white;
  }
`;

const NotifyWrapper = styled.div`
  & > div {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;
`;

export default MainMobileHeader;
