import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import Link from 'next/link';
// components
import NotificationCenter from 'components/organisms/common/NotificationCenter';
import SearchInput from 'components/molecules/headers/SearchInput';
import Avatar from 'components/atoms/photos/Avatar';
import NotifyIcon from 'components/atoms/icons/Notify';
import IconButton from 'cds/buttons/Icon';
import FillButton from 'cds/buttons/Fill';
import OutlineButton from 'cds/buttons/Outline';
import Icon from 'cds/icons';
import BaseHeader, { HeaderBlank } from 'components/layout/headers/Base';
// hooks
import { useSelector } from 'hooks/common/useStore';
import useBridgeCallback from 'hooks/bridge/useBridgeCallback';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
import { isExistWindow } from 'lib/utils/helper';
// styles
import { palette, mediaQuery, onlyHover } from 'cds/styles';

/** 메인 헤더 */
const MainPcHeader = ({ ...props }) => {
  const router = useRouter();
  const [userInfo, existChat, existNotification] = useSelector(state => [
    state.user.info,
    state.user.newsCount.chat_unread_count,
    state.user.newsCount.news_unread_count,
  ]);

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [isEnableNotify, setIsEnableNotify] = useState(false);
  const [isVisibleNotify, setIsVisibleNotify] = useState(false);
  const isOpenMenu = useRef(true);
  const [naviIsHide, setNaviIsHide] = useState(false);

  const isExistUser = userInfo.isLoaded && userInfo.id;

  useBridgeCallback({ onOpenNotify });

  const onToggleMenu = () => {
    setNaviIsHide(prev => !prev);

    const menu = document.querySelector('#left-side');
    const content = document.querySelector('#right-side');
    if (!menu || !content) {
      return;
    }

    if (isOpenMenu.current) {
      isOpenMenu.current = false;
      menu.style.display = 'none';
      content.style.maxWidth = '100%';
    } else {
      isOpenMenu.current = true;
      menu.removeAttribute('style');
      content.removeAttribute('style');
    }
  };

  const onLogin = () => {
    const loginRedirectUrl = isExistWindow() ? window.location.pathname : '';
    router.push(`/user?url=${loginRedirectUrl}`);
  };

  const onMouseDown = e => {
    if (e.buttons === 4) {
      const loginRedirectUrl = isExistWindow() ? window.location.pathname : '';
      router.push(`/user?url=${loginRedirectUrl}`);
    }
  };

  const onChat = () => {
    if (!isExistUser) {
      onLogin();
      return;
    }
    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat`;
  };

  const onToggleNotify = () => {
    if (!isExistUser) {
      onLogin();
      return;
    }
    setIsVisibleNotify(v => !v);
  };

  function onOpenNotify() {
    if (!isExistUser) {
      onLogin();
      return;
    }

    const notifyEvent = new CustomEvent('onOpenNotify', {
      detail: {
        winWidth: window.outerWidth,
      },
    });

    document.dispatchEvent(notifyEvent);
  }

  const doOpenNotify = ({ detail }) => {
    const { winWidth } = detail;
    if (winWidth < mediaQuery.large) {
      return;
    }

    setIsVisibleNotify(true);
  };

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  /** 알림 아이콘 표시 여부 */
  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      setIsEnableNotify(false);
    } else {
      setIsEnableNotify(true);
    }
  }, []);

  useEffect(() => {
    if (!isEnableNotify) {
      return;
    }

    document.addEventListener('onOpenNotify', doOpenNotify);

    return () => {
      document.removeEventListener('onOpenNotify', doOpenNotify);
    };
  }, [isEnableNotify]);

  const LeftSide = (
    <LeftSideLayout>
      <MenuButton onClick={onToggleMenu}>
        <Icon name="ic_category" fill={palette.grey75} />
      </MenuButton>
      <a href="/">
        <IconWrapper>
          <Icon name="logo_white" width={110} height={20} />
          <Icon name="logo_symbol" width={12} height={13} />
        </IconWrapper>
      </a>
    </LeftSideLayout>
  );

  const RightSide = (
    <RightSideLayout>
      <a href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/open`}>
        <ClassOpenButton size="32px" color={palette.grey020}>
          클래스개설
        </ClassOpenButton>
      </a>

      <a href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat`}>
        <ItemButton width={32} height={32} onClick={onChat}>
          <NotifyIcon
            name="ic_chat"
            fill={palette.BTN.Color0}
            width={24}
            height={24}
            isActive={!!existChat}
          />
        </ItemButton>
      </a>

      {isEnableNotify && (
        <ItemButton width={32} height={32} onClick={onToggleNotify}>
          <NotifyIcon
            width={24}
            height={24}
            isActive={!!existNotification}
            fill={palette.BTN.Color0}
          />
        </ItemButton>
      )}

      {isExistUser && (
        <Link href={`/user/${userInfo.id}`}>
          <a>
            <AvatarButton>
              <Avatar width={32} height={32} src={userInfo.photo} alt={userInfo.name} />
            </AvatarButton>
          </a>
        </Link>
      )}

      {!isExistUser && userInfo.isLoaded && (
        <LoginButton
          size="32px"
          color={palette.grey050}
          onClick={onLogin}
          onMouseDown={onMouseDown}
        >
          로그인
        </LoginButton>
      )}

      {!userInfo.isLoaded && <LoginSkeleton />}
    </RightSideLayout>
  );

  return (
    <>
      <BaseHeader {...props}>
        <ContentLayout>
          <LeftSideContainer naviIsHide={naviIsHide}>
            <Item>{LeftSide}</Item>
          </LeftSideContainer>

          <RightSideContainer>
            <SearchInputWrapper>{!isIpadApp && <SearchInput />}</SearchInputWrapper>

            <RightItem>{RightSide}</RightItem>
          </RightSideContainer>
        </ContentLayout>
      </BaseHeader>
      <HeaderBlank />

      {isExistUser && <NotificationCenter isVisible={isVisibleNotify} onClose={onToggleNotify} />}
    </>
  );
};

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: 240px calc(100vw - 240px);

  height: 100%;
  margin: auto;
`;

const LoginSkeleton = styled.div`
  width: 32px;
  height: 56px;
  margin-left: 12px;

  ${mediaQuery.large} {
    height: 32px;
  }
`;

const Item = styled.div`
  flex-grow: 1;
  width: 50px;

  &:first-child {
    display: flex;
    justify-content: flex-start;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const LeftSideLayout = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0;
`;

const RightSideLayout = styled.div`
  flex-grow: 1;
  display: none;
  position: relative;

  ${mediaQuery.large} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & > button,
  & > a:not(:first-child) {
    margin-left: 8px;
  }

  ${mediaQuery.large} {
    & > button,
    & > a:not(:first-child) {
      margin-left: 12px;
    }
  }
`;

const ClassOpenButton = styled(FillButton)`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  margin-right: 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${palette.grey50};
  background-color: ${palette.BTN.BG};
  min-width: 90px;

  ${onlyHover} {
    &:hover {
      ${({ disabled }) =>
        !disabled &&
        css`
          &::before {
            background-color: rgba(0, 0, 0, 0.04);
          }
        `};
    }
  }

  &:active {
    ${({ disabled }) =>
      !disabled &&
      css`
        &::before {
          background-color: rgba(0, 0, 0, 0.04);
        }
      `};
  }
`;

const ItemButton = styled(IconButton)`
  ${onlyHover} {
    &:hover {
      &:hover {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
`;

const MenuButton = styled(ItemButton)`
  margin-right: 12px;
`;

const AvatarButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  & > div {
    border: 1px solid white;
  }
`;

const LoginButton = styled(OutlineButton)`
  flex-shrink: 0;
  flex-grow: 0;
  padding: 6px 12px;
  font-size: 12px;
  color: ${palette.grey50};
  background-color: ${palette.BTN.BG};
  border: none;
  cursor: pointer;
`;

const LeftSideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 36px;
  background-color: ${({ naviIsHide }) => (naviIsHide ? palette.system.BG : palette.system.BNB)};
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 48px;
  background-color: ${palette.system.BG};
`;

const RightItem = styled.div`
  min-width: 240px;
`;

const SearchInputWrapper = styled.div`
  width: calc(100% - 240px);
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;
`;

export default MainPcHeader;
